<div class="device-status-icon">
  <mat-icon
    *ngIf="statusIcon"
    [ngStyle]="{ 'color': statusIconColor }"
    title="{{showTooltip ? statusName : undefined}}"
  >{{ statusIcon }}</mat-icon>
  <div *ngIf="!showTooltip && statusName" class="device-status-icon__hint-content">
    {{ statusName }}
  </div>
</div>
