import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Subject, takeUntil } from 'rxjs';
import { DeviceShortInterface } from '../../interfaces/device.interface';
import { StatusDurationInterface } from '../robot-status-badge/interfaces/status-duration.interface';
import { RobotStatusBadgeService } from '../robot-status-badge/robot-status-bagde.service';

@Component({
  standalone: true,
  selector: 'app-device-status-icon',
  templateUrl: './device-status-icon.component.html',
  styleUrls: [
    './device-status-icon.component.scss',
  ],
  providers: [
    RobotStatusBadgeService,
  ],
  imports: [
    CommonModule,
    MatIconModule,
  ],
})
export class DeviceStatusIconComponent implements OnChanges, OnDestroy {
  @Input() device: DeviceShortInterface;
  @Input() showTooltip = false;
  statusIcon: string | undefined;
  statusIconColor: string | undefined;
  statusName: string | undefined;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private robotStatusBadgeService: RobotStatusBadgeService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnChanges(): void {
    this.destroy$.next();
    this.robotStatusBadgeService.autoRefreshStatus$(
      this.device.robot_state_changed_at,
      this.device.status_id,
      this.device.id,
    ).pipe(
      takeUntil(this.destroy$),
    ).subscribe((resp: StatusDurationInterface) => {
      this.statusIcon = resp.statusOverallIcon;
      this.statusIconColor = resp.statusOverallColor;
      this.statusName = resp.statusName;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
